import * as icons from './icons';

export { icons as icons }

export const contacts = [
  { channel: "Mobile", link: "tel:+66899239912", label: "(+66) 089-923-9912" },
  { channel: "Email", link: "mailto:service@chakrit.net", label: "service@chakrit.net" },
  { channel: "Telegram", link: "https://t.me/chakritw", label: "t.me/chakritw" },
  { channel: "LinkedIn", link: "https://www.linkedin.com/in/chakritw", label: "linkedin.com/in/chakritw" },
  { channel: "GitHub", link: "https://github.com/chakrit", label: "github.com/chakrit" },
]

export const jobs = [
  {
    begin: "Oct 2023"
    , end: "current"
    , title: "IT Manager"
    , company: "Hatari Electric Co., Ltd."
    , summary: "Initiate a company-wide ERP replacement and multiple other internal software projects. Restructure and manage the IT department. Give advice to owners for various software endeavors."
    , technologies: ["Go", "Svelte", "PostgreSQL", "Kubernetes", "SQL Server", "C#"]
  },
  {
    begin: "Feb 2022"
    , end: "current"
    , title: "Chief Technology Officer"
    , company: "Forward Insight Co., Ltd."
    , summary: "Setup and manage initial development teams, structure and architecture for a newly formed logistics ERP startup. Continue to provide guidance to the team and give overall technical directions."
    , technologies: ["Python", "Django", "Svelte", "PostgreSQL", "Kubernetes"]
  },
  {
    begin: "Dec 2020"
    , end: "current"
    , title: "Founder"
    , company: "PRODIGY9 Co., Ltd"
    , summary: "Setup and manage multiple projects and delivery teams. Provided technical (and tech-business) guidance to multiple other startup founders. Created platform and development tooling to accelerate developments."
    , technologies: ["Go", "Rust", "PostgreSQL", "Kubernetes"]
  },
  {
    begin: "Jun 2019"
    , end: "Dec 2020"
    , title: "Chief Technology Officer"
    , company: "Muang Thai Broker Co., Ltd."
    , summary: "Setup and run teams of software engineers developing multiple products as well as handling internal company IT processes. Provide policies and processes for ops, development, and QA."
    , technologies: ["Ruby on Rails", "Rust", "TypeScript", "Kubernetes", "Redis", "PostgreSQL", "Meatspace"]
  }
  , {
    begin: "Nov 2019"
    , end: "Nov 2020"
    , title: "Lead Software Engineer"
    , company: "T-Box Co., Ltd."
    , summary: "Built the first version of white-labelled ICO portal in Bangkok, Thailand with a team of 3. This version of software allowed the company to be the first to obtain ICO portal license from the SEC. Also provided technical implementation advice to the SEC where needed as the technology is new."
    , technologies: ["Ethereum Web3", "Go", "Elm", "Kubernetes"]
  }
  , {
    begin: "Jan 2018"
    , end: "Nov 2019"
    , title: "Director of Engineering"
    , company: "Omise Co., Ltd."
    , summary: "Improves and help run developer recruitment and on-boarding process as well as day-to-day people operations within the engineering team. Additionally does code review, mentoring and coaching for other engineers."
    , technologies: ["Ruby on Rails", "Go", "gRPC", "Meatspace"]
  }
  , {
    begin: "Nov 2015"
    , end: "Jan 2018"
    , title: "Senior Developer"
    , company: "Omise Co., Ltd."
    , summary: "Develop and maintain API server as well as mobile applications for the Omise API on both iOS and Android platform. Help rework multiple SDK for connecting to the API to be more idiomatic based on the language."
    , technologies: ["Ruby on Rails", "Go", "C#", "Java", "Swift"]
  }
  , {
    begin: "Jul 2014"
    , end: "Sep 2015"
    , title: "Senior Developer"
    , company: "Hyperworks Co., Ltd."
    , summary: "Develop and maintain API and web servers and both iOS and Android application. Uses Swift and React when it was initially released. Also wrote a communication bridge module in C# for communicating with a unix server written in Go utilizing protocol buffers."
    , technologies: ["Swift", "Java", "Go", "C#", "Objective-C"]
  }
  , {
    begin: "Nov 2013"
    , end: "Jul 2014"
    , title: "Technical Co-Founder"
    , company: "Suffixlab Co., Ltd"
    , summary: "With only a team of two, developed a complete content management solution including backoffice servers, back-end API as well as native Android and iOS clients. Created a small web framework on Go in the process."
    , technologies: ["Go", "Objective-C", "Java"]
  }
  , {
    begin: "Nov 2011"
    , end: "Nov 2013"
    , title: "iOS / Android / Node.js Developer"
    , company: "Oozou Ltd.,"
    , summary: "Design, develop and maintain a large-scale high-speed node.js publish/subscribe messaging system and an accompanying text-based gossip TCP protocol for relaying message between huge number of customized home automation devices. Made an Android-SDK for talking to said protocol. Additionally have also worked on integrating PJSIP with an iOS application to provide SIP dialing capability for a community application."
    , technologies: ["Objective-C", "Node.js", "Shell Scripts", "Java", "Ruby on Rails", "CoffeeScript"]
  }
  , {
    begin: "2011"
    , end: "2011"
    , title: "Developer"
    , company: "Hua Lumpong Co., Ltd."
    , summary: "Develop mobile applications on a very wide range of platforms, including iOS, Android, Windows Mobile, Samsung Bada and Blackberries. Also did a small backend app for the company's Hackathon event with automatic online verification of solutions."
    , technologies: ["Java", "C#", "Objective-C", "C", "C++"]
  }
  , {
    begin: "2010"
    , end: "2011"
    , title: "Founder"
    , company: "2nitedesign Co., Ltd"
    , summary: "Bootstraping by doing cuonsulting work. Built and open-sourced Sider, which is a C# binding to the Redis wire protocol when Redis was initially released and there was not a lot of drivers available."
    , technologies: ["Redis", "C#", "JavaScript"]
  }
  , {
    begin: "2009"
    , end: "2010"
    , title: "Senior Developer"
    , company: "Ecartstudio Co., Ltd."
    , summary: "Add features to a legacy mapping application where the source files are obfuscated and no original source available. Wrote tools to compress terabytes of PNG images (map tiles) into optimized 8-bit PNG reducing storage requirements by almost half. Also written ~40 pages of Wiki documentation detailing the mapping engine. Finally rewriting the engine in Silverlight using DeepZoom library and an integration layer that allows the engine to be drop-in replacement for Google Maps with no code changes."
    , technologies: ["GIS", "JavaScript", "C#"]
  }
]
